<template>
  <div class="container" v-title :data-title="$t('i18n.videoMonitor')">
    <div id="outer-title">{{ $t("i18n.videoMonitor") }}</div>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
      >
      </jl-table>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import jlTable from "../../components/table";
export default {
  name: "VideoSurveillance",
  components: {
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const state = reactive({
      tableData: [],
      total: 0,
      loading: true,
      options: {
        // table 的参数
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "number",
          label: "elevatorNo",
          align: "center",
          search: true,
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "projectName",
          label: "projectName",
          width: 260,
          align: "left",
          search: true,
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "equipmentNumber",
          label: "equipmentNo",
          align: "center",
          search: false,
        },
        {
          prop: "elevatorType",
          label: "elevatorType",
          align: "center",
          search: false,
        },
        {
          prop: "status",
          label: "runningState",
          align: "center",
          search: false,
          formatter: (row) => {
            return row.status == 10 ? t("i18n.online") : t("i18n.offline");
          },
        },
        {
          prop: "floor",
          label: "currentFloor",
          align: "center",
          search: false,
        },
        {
          prop: "flow",
          label: "upDown",
          align: "center",
          search: false,
          formatter: (row) => {
            if (row.flow == "10") {
              return `<img src="../../assets/flowupgreen.png"
                class="direction-up" />`;
            } else if (row.flow == "01") {
              return `<img src="../../assets/flowdowngreen.png"
                class="direction-down" />`;
            } else {
              return "-";
            }
          },
        },
        {
          label: "video",
          align: "center",
          search: false,
          formatter: (row) => {
            return `<a href="${proxy.$config.url.videoForThree + row.number}"
              target="_blank">${t("i18n.view")}</a>`;
          },
        },
      ],
      operates: {
        // 列操作按钮
        width: 200,
        fixed: "right",
        list: [],
      },
    });

    const init = async (params) => {
      state.loading = true;
      const { data } = await proxy.$api.monitor.eleMonitors(params);
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    return {
      ...toRefs(state),
      init,
    };
  },
};
</script>

<style lang="scss"></style>
